import React, { ReactElement, useEffect, useRef } from 'react';
import "./style_pricing.css"
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

  
export const CWPricing = (): ReactElement => {
    const pricingRef = useRef(null);
    const stickyDetectionRef = useRef(null);
    const { isVisible } = useVisibilityDetection(pricingRef, [0.1, 0.9]);
    const { isVisible: shouldHideTopBar } = useVisibilityDetection(stickyDetectionRef, [0, 1], true);

    useEffect(()=>{
        const pagestyle = `
          *{
                margin: 0;
                padding: 0;
            } 
        `

        var pageStyleSheet = document.createElement("style")
        pageStyleSheet.textContent = pagestyle
        document.head.appendChild(pageStyleSheet)


        const deal_qstr =  window.location.href.split('?')[1];
        
        let links = document.getElementsByClassName("new-pricing-link");
        let linkssub = document.getElementsByClassName("new-pricing-link-sub");

        let link = "https://checkout.sketchwow.com/?add-to-cart=407";
        let linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352";
        // if(deal_qstr.includes('swp=sl')){
        //     link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sl"; 
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=sl"; 
        // }else if(deal_qstr.includes('swp=sv')){
        //      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sv";
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=sv"; 
        // }else if(deal_qstr.includes('swp=of')){
        //      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=of";
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=of"; 
        // }else if(deal_qstr.includes('swp=lt')){
        //      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=lt"; 
        // }else if(deal_qstr.includes('swp=ltd')){
        //     link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=ltd"; 
        // }else if(deal_qstr.includes('swp=dl')){
        //      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=dl"; 
        // }else if((deal_qstr.includes('swp=pr')) && localStorage.getItem('refid')){
        //     link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');
        //     linksubs = "https://checkout.sketchwow.com?add-to-cart=816352&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');
        // }else{
        //    link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home";
        //    linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352&swp=home";
        // }
           
        if(typeof deal_qstr === 'undefined'){
            link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home";
            linksubs = "https://checkout.sketchwow.com/?add-to-cart=816352&swp=home";
        }

        if(typeof deal_qstr !== 'undefined' &&  links !== null){
          let i;
          for (i = 0; i < links.length; i++) {
            links[i].href = link + '&'  + deal_qstr;
          }
        }else if(links !== null){
          let i;
          for (i = 0; i < links.length; i++) {
            links[i].href = link;
          }
        }

        if(typeof deal_qstr !== 'undefined' &&  linkssub !== null){
            let i;
            for (i = 0; i < linkssub.length; i++) {
              linkssub[i].href = linksubs + '&'  + deal_qstr;
            }
          }else if(linkssub !== null){
            let i;
            for (i = 0; i < linkssub.length; i++) {
              linkssub[i].href = linksubs;
            }
          }

        
    })
  return (
    <Styled.Wrapper isVisible={isVisible} ref={pricingRef} id="pricing">

    <div className="cw-container-pricing">
         <div className="cw-bg-color">
            <div className="cw-wrapper">
                <div className="cw-logo">
                    <img src="/cwfiles/sketch-logo.png" alt=""/>
                </div>
                <div className="cw-subscription">
                    <div className="cw-price-pricing">$39<sub>/mo</sub></div>
                    <h2 className="cw-sub">Subscription</h2>
                    <h5 className="cw-bill">(Billed Monthly)</h5>
            
                </div>
                <div className="cw-subscription2-pricing">
                    <div className="cw-price-pricing">$49</div>
                    <h2 className="cw-sub">Lifetime</h2>
                    <h5 className="cw-bill">(One-time Payment)</h5>
            
                </div>
            </div>
            <ul className="cw-list-container">
                <li><div className="text">Unlimited sketches</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">AI-powered mindmaps, flows, visuals</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">100+ templates</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">350 elements, shapes, icons</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Standard color backgrounds</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">30 fonts (+ import custom fonts)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Resize canvas (Custom size)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Import Images (Drag-and-drop)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">JPG, PNG, SVG, PDF, Animated GIF (Export)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Presentation mode</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">File backup & restore</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Use on multiple computers</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Standard support</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
            
            </ul>
            <div className="button-wrapper">
                <div className="empty-box"></div>
                <div className="startedbutton"><a href="https://checkout.sketchwow.com/?add-to-cart=816352&swp=home" className='new-pricing-link-sub'><button type="button">get started</button></a></div>
                <div className="startedbutton active"><a href="https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home" className='new-pricing-link'><button type="button">get started</button></a></div>
            </div>
            </div>
    </div>
            <Styled.BackgroundWrapper>
            <Styled.Background>
              <Styled.BackgroundIllustrations />
            </Styled.Background>
          </Styled.BackgroundWrapper>
              </Styled.Wrapper>      
  );

  
};
